// third-party components, hooks and packages
import { useEffect, useState } from "react";

// components
import { LAYOUT_DEFAULT } from "./components/layouts.jsx";
import Loader from "./components/loader.jsx";
import { NAVIGATION_PAGINATION } from "./components/navigations.jsx";

// styles
import "./styles/app.css"
				
export default function App () {
	// state holders
	const [isLoading, setIsLoading] = useState(false);
	const [items, setItems] = useState();
	const [paginationSize] = useState(24);
	const [tag, setTag] = useState("");
	// const [filter, setFilter] = useState("");
	const [videoURL, setVideoURL] = useState("");

	// event handlers
	const HANDLER_NAV_SEARCH = (query) => { return setTag(query.toLowerCase()); };
	// const HANDLER_NAV_SEARCH = (query) => {
	// 	let tags = query.toLowerCase();

	// 	if (query.includes(`&`)) {
	// 		setFilter({
	// 			operation: `AND`,
	// 			tags: tags.split(`&`)
	// 		})
	// 	} else if (query.includes(`,`)) {
	// 		setFilter({
	// 			operation: `OR`,
	// 			tags: tags.split(`,`)
	// 		})
	// 	} else { setFilter({ tags: [tags] }) }

	// 	console.log(`filter: `, filter);
	// }
	const HANDLER_NAV_TAB_CLICK = async (key) => {
		if (key) return setTag(key.replaceAll(`_`, ` `))
		if (tag) return setTag("");
		return paginationRouter(1)
	};
	// const HANDLER_NAV_TAB_CLICK = async (key) => {
	// 	if (key) return setTag([key.replaceAll(`_`, ` `)])
	// 	if (tag) return setTag([]);
	// 	return paginationRouter(1)
	// };
	const HANDLER_VIDEO_CLICK = (url) => {
        if (url) {
            let video_url;

            if(url.includes("allnporn.com")) video_url = "";
			else if(url.includes("analbabes.porn")) video_url = "";
            else if(url.includes("eporner.com")) video_url = `https://www.eporner.com/embed/${ url?.split(`/video-`)[1].split(`/`)[0] }/`;
            else if(url.includes("heavyfetish.com")) video_url = `https://heavyfetish.com/embed/${ url?.split(`videos/`)[1].split(`/`)[0] }`;
			else if(url.includes("hdsex.org")) video_url = `https://hdsex.org/embed/${ url?.split(`video/`)[1] }`;
            else if( url.includes("playvids.com")) video_url = `https://www.playvids.com/embed/${ url?.split(`/`)[3] }`;
            else if( url.includes("pornone.com")) video_url = `https://pornone.com/embed/${ url?.split(`/`)[4] }`;
            else if( url.includes("pornzog.com")) video_url = `http://pornzog.com/embed.php?id=${ url?.split(`/video/`)[1].split(`/`)[0] }`;
			else if(url.includes("spankbang.party")) video_url = `https://spankbang.party/${ url?.split(`party/`)[1].split(`/video`)[0] }/embed/`;
			else if(url.includes("sxyprn.com")) video_url = "";
            else if(url.includes("tnaflix.com")) video_url = `https://player.tnaflix.com/video/${ url?.split(`/video`)[1] }`;
            else if(url.includes("txxx.com")) video_url = `https://txxx.com/embed/${ url?.split(`/`)[4] }`;
            else if(url.includes("xhamster.com")) video_url = url;
            else if(url.includes("xvideos.com")) video_url = `https://www.xvideos.com/embedframe/${ url?.split(`/video`)[1].split(`/`)[0] }`;

            return setVideoURL(video_url)
        }

        // Undefined URL
		return setVideoURL()
	}

	const paginationRouter = async (pageNumber) => {
		setIsLoading(true)

		fetch(
			"https://mpc-server-98bd.onrender.com/",
			// "http://127.0.0.1:5001/mxc-prototype-c443b/us-central1/getVideos",
			// "http://localhost:7071/api/get_videos",
			// "http://localhost:8080/",
			{
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({
					"page": `${ pageNumber }`,
					"size": `${ paginationSize }`,
					"tag": `${ tag }`
				})
			}
		)
		.then(response => response.json())
		.then(data => { setItems(data) })
		.catch(error => { console.error('Error fetching link preview:', error); });
	}

	// watchers
	// eslint-disable-next-line
	useEffect(() => { paginationRouter(1) }, [tag]) // watching tab clicks
	// useEffect(() => { paginationRouter(1) }, [filter]) // watching tab clicks
	// eslint-disable-next-line
	useEffect(() => { if (isLoading) setIsLoading(false) }, [items]) // watching tab clicks
	
	// inits
	// eslint-disable-next-line
	useEffect(() => { paginationRouter(1) }, [])

	return (
		<LAYOUT_DEFAULT pageName={ `index` } navSearchCallback={ HANDLER_NAV_SEARCH } navTabClicked={ HANDLER_NAV_TAB_CLICK }>
			{
				videoURL ?
				<div id="video-player">
					<div id="close" className="icon close" onClick={ () => HANDLER_VIDEO_CLICK() }></div>
					<iframe id="video" src={ videoURL } frameborder="0" allowFullScreen={ true } title="Iframe"></iframe>
				</div> : null
			}

			<div id="items" className="grid">
				{
					items?.data && items?.data?.length > 0 ?
					items?.data?.map((item, key) => {
						return (
							<div onClick={ () => HANDLER_VIDEO_CLICK(item?.extract?.url) } className="item" key={ key }>
								<div className="image">
									{
										item?.extract?.images.length > 0 ?
										<img alt="preview" src={ item?.extract?.images[0] } /> :
										<div className="icon video fill-space"></div>
									}
								</div>
								<div className="title">
									{
										item?.extract?.title ?
										item?.extract?.title?.length > 50 ? `${ item?.extract?.title?.slice(0, 50) }...` : item?.extract?.title :
										item?.tags?.split(`,`)[item?.tags?.split(`,`).length - 1]
									}
								</div>
								<div className="description">{ item?.extract?.description?.length > 70 ? `${ item?.extract?.description?.slice(0, 70) }...` : item?.extract?.description }</div>
							</div>
						)
					}) :
					<div id="zero">
						<div>No Video</div>
						<div className="cta">Add Video</div>
					</div>
				}
			</div>

			{
				items?.totalItems > 24 ?
				<NAVIGATION_PAGINATION	last=
										{
											items?.totalItems / 24 > Math.floor(items?.totalItems / 24) ?
											Math.floor(items?.totalItems / 24) + 1 :
											Math.floor(items?.totalItems / 24)
										}
										callback={ paginationRouter }
										currentPage={ items?.page } /> : null
			}

			<Loader state={ isLoading } />
		</LAYOUT_DEFAULT>
	);
}
