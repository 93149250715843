export const TABS = [
    {
        "class": "Stars",
        "content": [
            {
                "id": "sasha_rae",
                "name": "Sasha Rae"
            }, {
                "id": "butterscotch",
                "name": "Butterscotch"
            }, {
                "id": "cali_sunshine",
                "name": "Cali Sunshine"
            }, {
                "id": "ms_juicy",
                "name": "Ms. Juicy"
            }, {
                "id": "chanel_star",
                "name": "Chanel Star"
            }, {
                "id": "cherokee_d_ass",
                "name": "Cherokee D'ass"
            }, {
                "id": "ambitious_booty",
                "name": "Ambitious Booty"
            }, {
                "id": "ms_cleo",
                "name": "Ms. Cleo"
            }, {
                "id": "yuri_dreamz",
                "name": "Yuri Dreamz"
            }, {
                "id": "halle_hayes",
                "name": "Halle Hayes"
            }, {
                "id": "unique_lasange",
                "name": "Unique Lasange"
            }, {
                "id": "blu_diamond",
                "name": "Blu Diamond"
            }, {
                "id": "aryana_adin",
                "name": "Aryana Adin"
            }, {
                "id": "vanity_cruz",
                "name": "Vanity Cruz"
            }, {
                "id": "codi_bryant",
                "name": "Codi Bryant"
            }, {
                "id": "dellota_brown",
                "name": "Dellota Brown"
            }, {
                "id": "diana_devoe",
                "name": "Diana Devoe"
            }, {
                "id": "diamond_mason",
                "name": "Diamond Mason"
            }, {
                "id": "kapri_styles",
                "name": "Kapri Styles"
            }, {
                "id": "nautica_monroe",
                "name": "Nautica Monroe"
            }, {
                "id": "ebony_banks",
                "name": "Ebony Banks"
            }, {
                "id": "alayah_sashu",
                "name": "Alayah Sashu"
            }, {
                "id": "amerika",
                "name": "Amerika"
            }, {
                "id": "ms_goddess",
                "name": "Ms. Goddess"
            }, {
                "id": "betty_boo",
                "name": "Betty Boo"
            }, {
                "id": "cocoa_dawn",
                "name": "Cocoa Dawn"
            }, {
                "id": "persuajon",
                "name": "Persuajon"
            }, {
                "id": "miami_davis",
                "name": "Miami Davis"
            }, {
                "id": "jaime_foxworth",
                "name": "Jaime Foxworth"
            }, {
                "id": "ashley_fox",
                "name": "Ashley Fox"
            }, {
                "id": "honey_staxxx",
                "name": "Honey Staxxx"
            }, {
                "id": "dee_rida",
                "name": "Dee Rida"
            }, {
                "id": "felisha_honey",
                "name": "Felisha Honey"
            }, {
                "id": "kelsi_monroe",
                "name": "Kelsi Monroe"
            }, {
                "id": "millian_blu",
                "name": "Millian Blu"
            }, {
                "id": "lily_starfire",
                "name": "Lily Starfire"
            }, {
                "id": "mena_carlisle",
                "name": "Mena Carlisle"
            }, {
                "id": "fransceska_jaimes",
                "name": "Fransceska Jaimes"
            }, {
                "id": "jesse_rogers",
                "name": "Jesse Rogers"
            }, {
                "id": "mandy_muse",
                "name": "Mandy Muse"
            }, {
                "id": "andreina_de_luxe",
                "name": "Andreina De Luxe"
            }, {
                "id": "lauren_phillips",
                "name": "Lauren Phillips"
            }, {
                "id": "diamond_kitty",
                "name": "Diamond Kitty"
            }, {
                "id": "sumaya",
                "name": "Sumaya"
            }, {
                "id": "ryan_conner",
                "name": "Ryan Conner"
            }, {
                "id": "alexxa_vice",
                "name": "Alexxa Vice"
            }, {
                "id": "canela_skin",
                "name": "Canela Skin"
            }, {
                "id": "lana_rhoades",
                "name": "Lana Rhoades"
            }, {
                "id": "syren_de_mer",
                "name": "Syren De Mer"
            }, {
                "id": "venus_afrodita",
                "name": "Venus Afrodita"
            }
        ]
    }, {
        "class": "Channels",
        "content": [
            {
                "id": "ghetto_gaggers",
                "name": "Ghetto Gaggers"
            }, {
                "id": "woodman_casting",
                "name": "Woodman Casting"
            }, {
                "id": "black_on_black_crime",
                "name": "Black on Black Crime"
            }, {
                "id": "backroom_caasting_couch",
                "name": "Backroom Casting Couch"
            }, {
                "id": "broker_amateurs",
                "name": "Broker Amateurs"
            }, {
                "id": "porndude_casting",
                "name": "Porndude Casting"
            }, {
                "id": "mompov",
                "name": "MomPov"
            }, {
                "id": "jaht",
                "name": "Jah T"
            }, {
                "id": "lorenzo",
                "name": "Lorenzo"
            }, {
                "id": "herlimit",
                "name": "HerLimit"
            },
        ]
    }, {
        "class": "Tags",
        "content": [
            {
                "id": "black",
                "name": 'Black'
            }, {
                "id": "busty",
                "name": 'Busty'
            }, {
                "id": "interracial",
                "name": 'Interracial'
            }, {
                "id": "light_skin",
                "name": 'Light Skin'
            }, {
                "id": "slim_thick",
                "name": 'Slim Thick'
            }, {
                "id": "hot",
                "name": 'Hot'
            }, {
                "id": "pawg",
                "name": 'PAWG'
            }, {
                "id": "milf",
                "name": 'MILF'
            }, {
                "id": "public",
                "name": 'Public'
            }, {
                "id": "pickup",
                "name": 'Pickup'
            }, {
                "id": "hentai",
                "name": "Hentai"
            }, {
                "id": "african",
                "name": "African"
            },
        ]
    }
]