// third-party components, hooks, libraries and packages
import { useEffect, useState } from "react";

// styles
import "../styles/navigations.css"

// data
import { TABS } from "../data/tabs.js";

export function NAVIGATION_FILTER_BAR ({ searchCallback, tabClicked }) {
    // state holders
	const [activeTab, setActiveTab] = useState();
	const [ribbonIndex, setRibbonIndex] = useState(0);
	const [searchQuery, setSearchQuery] = useState("");

    // functions
	const HANDLER_CHANGE_TAB_GROUP = (direction) => {
		if (direction === `next`) setRibbonIndex(ribbonIndex === TABS?.length - 1 ? 0 : ribbonIndex + 1)
		else if (direction === `previous`) setRibbonIndex(ribbonIndex === 0 ? TABS?.length - 1 : ribbonIndex - 1)
	}
	const HANDLER_FORWARD_SEARCH = (action) => {
        // send back query
		action.preventDefault();
        if (searchQuery && searchQuery !== "") return searchCallback(searchQuery);
        return        
	}
	const HANDLER_TAB_CLICKED = (tab_id) => {
        setActiveTab(tab_id)
        tabClicked(tab_id)
    }

    // watchers
	useEffect(() => { document.querySelector(`.ribbons`)?.children[ribbonIndex].scrollIntoView() }, [ribbonIndex])

    return (
        <div className="navigation filter-bar">
            <div className="icon shuffle" onClick={ () => HANDLER_TAB_CLICKED() }></div>
            
            <form action="POST" onSubmit={ (e) => HANDLER_FORWARD_SEARCH(e) }>
                <input type="search" autoComplete="true" name="input_search" id="input_search" placeholder="Search" onChange={ ({ target }) => setSearchQuery(target.value) } />
                <button type="submit" onClick={ (e) => HANDLER_FORWARD_SEARCH(e) }><div className="icon search" title="search"></div></button>
            </form>

            <div className="filter">
                <div className="presentation">Filter by:</div>
                <div className="current">{ TABS[ribbonIndex].class }</div>
            </div>

            <div className="controls">
                <div onClick={ () => HANDLER_CHANGE_TAB_GROUP(`previous`) }><div className="icon chevron-left" title="left"></div></div>
                <div onClick={ () => HANDLER_CHANGE_TAB_GROUP(`next`) }><div className="icon chevron-right" title="right"></div></div>
            </div>

            <div className="ribbons">
                {
                    TABS.map((group, index) => {
                        return (
                            <div key={ index } className="ribbon">
                                {
                                    group?.content?.sort((a, b) => {
                                        var keya = a?.name;
                                        var keyb = b?.name;

                                        if (keya < keyb) return -1;
                                        if (keya > keyb) return 1;

                                        return 0
                                    }).map((item, index) => {
                                        return (
                                            <div key={ index } className={ `tab ${ activeTab === item?.id ? `active` : null }` } onClick={ () => HANDLER_TAB_CLICKED(item?.id) }>
                                                { item?.name }
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export function NAVIGATION_PAGINATION ({ callback, currentPage, last }) {
    const mapper = Array.from({ length: last }, (_, index) => index + 1)

    return (
        <div className="navigation pagination">{
            mapper?.map((item, key) => {
                return (
                    <div key={ key } onClick={ () => callback(key + 1) } className={ key + 1 === currentPage ? `active` : null }>{ key + 1 }</div>
                )
            })
        }</div>
    )
}