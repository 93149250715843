// components
import { NAVIGATION_FILTER_BAR } from "./navigations"

// styles
import "../styles/layouts.css"

export function LAYOUT_DEFAULT ({ children, pageName, navSearchCallback, navTabClicked }) {
    return (
        <div id="page" data-page-name={ pageName }>
            <div id="navigation">
                <NAVIGATION_FILTER_BAR searchCallback={ navSearchCallback } tabClicked={ navTabClicked } />
            </div>
            <div id="content">{ children }</div>
        </div>
    )
}